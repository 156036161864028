<template>
  <vs-row>
    <vs-col vs-w="6" class="centering">
      <vs-chip color="success" v-if="whatsappBroadcastData !== 'No whatsappBoradcasts for today'">
        <span><b>Today's Whatsapp Broadcast </b></span>&nbsp;&nbsp;<span
          class="broadcastBarStyle"
          >Total : {{ whatsappBroadcastData.total_count }}</span
        >&nbsp;&nbsp;<span class="broadcastBarStyle"
          >Completed : {{ whatsappBroadcastData.completed_count }}</span
        >&nbsp;&nbsp;<span class="broadcastBarStyle"
          >Failure : {{ whatsappBroadcastData.failure_count }}</span
        >&nbsp;&nbsp;
        <span v-if="whatsappBroadcastData.approval_status === 'approved'">
          <span
            class="broadcastControlButtons"
            :data-id="whatsappBroadcastData.id"
            data-type="pause"
            v-if="whatsappBroadcastData.status === 'running'"
            @click="
              changeBroadcastStatus(
                `pauseBroadcast/${whatsappBroadcastData.id}`
              )
            "
            ><img src="https://img.icons8.com/ios-glyphs/20/000000/pause.png"
          /></span>
          &nbsp;&nbsp;
          <span
            class="broadcastControlButtons"
            :data-id="whatsappBroadcastData.id"
            data-type="cancel"
            v-if="whatsappBroadcastData.status === 'running'"
            @click="
              changeBroadcastStatus(
                `cancelBroadcast/${whatsappBroadcastData.id}`
              )
            "
            ><img
              src="https://img.icons8.com/ios-glyphs/20/000000/stop.png"/></span
          >&nbsp;&nbsp;
          <span
            class="broadcastControlButtons"
            :data-id="whatsappBroadcastData.id"
            data-type="resume"
            v-if="whatsappBroadcastData.status === 'paused'"
            @click="
              changeBroadcastStatus(
                `resumeBroadcast/${whatsappBroadcastData.id}`
              )
            "
            ><img
              v-if="getrole('cm1') || getrole('exed2')"
              src="https://img.icons8.com/ios-glyphs/20/000000/play.png"
          /></span>
          &nbsp;&nbsp;
          <span v-if="whatsappBroadcastData.status === 'cancelled'"
            ><img src="https://img.icons8.com/ios-glyphs/20/000000/cancel-2.png"
          /></span>
          &nbsp;&nbsp;
          <span v-if="whatsappBroadcastData.status === 'completed'"
            ><img
              src="https://img.icons8.com/ios-glyphs/20/000000/ok.png"/></span
        ></span>
        &nbsp;&nbsp;
      </vs-chip>
    </vs-col>
    <vs-col vs-w="6" class="centering">
      <vs-chip color="success" v-if="delayWhatsappBroadcastData !== 'No whatsappBoradcasts delays for till date' ">
        <span><b>Delays's Whatsapp Broadcast </b></span>&nbsp;&nbsp;<span
          class="broadcastBarStyle"
          >Total : {{ delayWhatsappBroadcastData.total_count }}</span
        >&nbsp;&nbsp;<span class="broadcastBarStyle"
          >Completed : {{ delayWhatsappBroadcastData.completed_count }}</span
        >&nbsp;&nbsp;<span class="broadcastBarStyle"
          >Failure : {{ delayWhatsappBroadcastData.failure_count }}</span
        >&nbsp;&nbsp;
        <span v-if="delayWhatsappBroadcastData.approval_status === 'approved'">
          <span
            class="broadcastControlButtons"
            :data-id="delayWhatsappBroadcastData.id"
            data-type="pause"
            v-if="delayWhatsappBroadcastData.status === 'running'"
            @click="
              changeBroadcastStatus(
                `pauseBroadcast/${delayWhatsappBroadcastData.id}`
              )
            "
            ><img src="https://img.icons8.com/ios-glyphs/20/000000/pause.png"
          /></span>
          &nbsp;&nbsp;
          <span
            class="broadcastControlButtons"
            :data-id="delayWhatsappBroadcastData.id"
            data-type="cancel"
            v-if="delayWhatsappBroadcastData.status === 'running'"
            @click="
              changeBroadcastStatus(
                `cancelBroadcast/${delayWhatsappBroadcastData.id}`
              )
            "
            ><img
              src="https://img.icons8.com/ios-glyphs/20/000000/stop.png"/></span
          >&nbsp;&nbsp;
          <span
            class="broadcastControlButtons"
            :data-id="delayWhatsappBroadcastData.id"
            data-type="resume"
            v-if="delayWhatsappBroadcastData.status === 'paused'"
            @click="
              changeBroadcastStatus(
                `resumeBroadcast/${delayWhatsappBroadcastData.id}`
              )
            "
            ><img
              v-if="getrole('cm1') || getrole('exed2')"
              src="https://img.icons8.com/ios-glyphs/20/000000/play.png"
          /></span>
          &nbsp;&nbsp;
          <span v-if="delayWhatsappBroadcastData.status === 'cancelled'"
            ><img src="https://img.icons8.com/ios-glyphs/20/000000/cancel-2.png"
          /></span>
          &nbsp;&nbsp;
          <span v-if="delayWhatsappBroadcastData.status === 'completed'"
            ><img
              src="https://img.icons8.com/ios-glyphs/20/000000/ok.png"/></span
        ></span>
        &nbsp;&nbsp;
      </vs-chip>
    </vs-col>
  </vs-row>
</template>

<script>
import swal from "sweetalert2/src/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import axios from "axios";
import constants from "../../../constants.json";
export default {
  data() {
    return {
      interval: null,
      whatsappBroadcastData: [],
      delayWhatsappBroadcastData: [],
    };
  },
  mounted() {
    this.myprofile();

    this.$nextTick(function() {
      window.setInterval(() => {
        this.myprofile();
        if (this.whatsappBroadcastData.status === "paused") {
          this.callingStatus();
        }
      }, 900000);
    });
  },

  methods: {
    changeBroadcastStatus(endpoint) {
      swal
        .fire({
          title: "Are you sure?",
          text: "You want to start this ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.value) {
            let url = `${constants.MILES_CM_BACK}${endpoint}`;
            axios
              .post(
                url,
                {},
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.userAccessToken}`,
                  },
                }
              )
              .then((response) => {
                console.log(response);
                if (response.data === "success") {
                  this.myprofile();
                }
                // this.broadcasts = response.data;
              })
              .catch((error) => {
                this.handleError(error);
              });
          }
        });
      // console.log(status);
    },
    myprofile() {
      let url = `${constants.SERVER_API}getUserProfile`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("My profile", response.data.data);
          let data = response.data.data;
          let userid = data.id;
          console.log(userid);
          // this.todayWhatsappBroadcastCount(userid);
          // this.todayDelaysWhatsappBroadcastCount(userid);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    // todayWhatsappBroadcastCount(userid) {
    //   let url = `${constants.MILES_CM_BACK}getDailyWhatsappBoradcast`;
    //   let getParams = {
    //     spoc_id: userid,
    //   };
    //   axios
    //     .get(url, {
    //       params: getParams,
    //       headers: {
    //         Authorization: `Bearer ${localStorage.userAccessToken}`,
    //       },
    //     })
    //     .then((response) => {
    //       console.log("whatsapp Broadcast Data", response.data);
    //       this.whatsappBroadcastData = response.data;
    //     })
    //     .catch((error) => {
    //       this.handleError(error);
    //     });
    // },
    // todayDelaysWhatsappBroadcastCount(userid) {
    //   let url = `${constants.MILES_CM_BACK}getDailyDelaysWhatsappBoradcast`;
    //   let getParams = {
    //     spoc_id: userid,
    //   };
    //   axios
    //     .get(url, {
    //       params: getParams,
    //       headers: {
    //         Authorization: `Bearer ${localStorage.userAccessToken}`,
    //       },
    //     })
    //     .then((response) => {
    //       console.log("today delays whatsapp Broadcast Data", response.data);
    //       this.delayWhatsappBroadcastData = response.data;
    //     })
    //     .catch((error) => {
    //       this.handleError(error);
    //     });
    // },
    callingStatus() {
      this.$vs.notify({
        title: "Your Whatsapp Broadcast is Paused",
        text: "To start, Click Play button in top. ",
        color: "primary",
        time: 600000,
        icon: "play_arrow",
      });
    },
  },
  created() {},
};
</script>

<style>
.broadcastBarStyle {
  padding: 2px;
  background: #ffffff;
  color: black;
  border-radius: 2px;
}
.centering {
  justify-content: center;
  display: flex;
}
div.swal2-container {
  z-index: 50000;
}
</style>
