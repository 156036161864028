var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vs-row',[_c('vs-col',{staticClass:"centering",attrs:{"vs-w":"6"}},[(_vm.whatsappBroadcastData !== 'No whatsappBoradcasts for today')?_c('vs-chip',{attrs:{"color":"success"}},[_c('span',[_c('b',[_vm._v("Today's Whatsapp Broadcast ")])]),_vm._v("  "),_c('span',{staticClass:"broadcastBarStyle"},[_vm._v("Total : "+_vm._s(_vm.whatsappBroadcastData.total_count))]),_vm._v("  "),_c('span',{staticClass:"broadcastBarStyle"},[_vm._v("Completed : "+_vm._s(_vm.whatsappBroadcastData.completed_count))]),_vm._v("  "),_c('span',{staticClass:"broadcastBarStyle"},[_vm._v("Failure : "+_vm._s(_vm.whatsappBroadcastData.failure_count))]),_vm._v("  \n      "),(_vm.whatsappBroadcastData.approval_status === 'approved')?_c('span',[(_vm.whatsappBroadcastData.status === 'running')?_c('span',{staticClass:"broadcastControlButtons",attrs:{"data-id":_vm.whatsappBroadcastData.id,"data-type":"pause"},on:{"click":function($event){return _vm.changeBroadcastStatus(
              ("pauseBroadcast/" + (_vm.whatsappBroadcastData.id))
            )}}},[_c('img',{attrs:{"src":"https://img.icons8.com/ios-glyphs/20/000000/pause.png"}})]):_vm._e(),_vm._v("\n          \n        "),(_vm.whatsappBroadcastData.status === 'running')?_c('span',{staticClass:"broadcastControlButtons",attrs:{"data-id":_vm.whatsappBroadcastData.id,"data-type":"cancel"},on:{"click":function($event){return _vm.changeBroadcastStatus(
              ("cancelBroadcast/" + (_vm.whatsappBroadcastData.id))
            )}}},[_c('img',{attrs:{"src":"https://img.icons8.com/ios-glyphs/20/000000/stop.png"}})]):_vm._e(),_vm._v("  \n        "),(_vm.whatsappBroadcastData.status === 'paused')?_c('span',{staticClass:"broadcastControlButtons",attrs:{"data-id":_vm.whatsappBroadcastData.id,"data-type":"resume"},on:{"click":function($event){return _vm.changeBroadcastStatus(
              ("resumeBroadcast/" + (_vm.whatsappBroadcastData.id))
            )}}},[(_vm.getrole('cm1') || _vm.getrole('exed2'))?_c('img',{attrs:{"src":"https://img.icons8.com/ios-glyphs/20/000000/play.png"}}):_vm._e()]):_vm._e(),_vm._v("\n          \n        "),(_vm.whatsappBroadcastData.status === 'cancelled')?_c('span',[_c('img',{attrs:{"src":"https://img.icons8.com/ios-glyphs/20/000000/cancel-2.png"}})]):_vm._e(),_vm._v("\n          \n        "),(_vm.whatsappBroadcastData.status === 'completed')?_c('span',[_c('img',{attrs:{"src":"https://img.icons8.com/ios-glyphs/20/000000/ok.png"}})]):_vm._e()]):_vm._e(),_vm._v("\n        \n    ")]):_vm._e()],1),_c('vs-col',{staticClass:"centering",attrs:{"vs-w":"6"}},[(_vm.delayWhatsappBroadcastData !== 'No whatsappBoradcasts delays for till date' )?_c('vs-chip',{attrs:{"color":"success"}},[_c('span',[_c('b',[_vm._v("Delays's Whatsapp Broadcast ")])]),_vm._v("  "),_c('span',{staticClass:"broadcastBarStyle"},[_vm._v("Total : "+_vm._s(_vm.delayWhatsappBroadcastData.total_count))]),_vm._v("  "),_c('span',{staticClass:"broadcastBarStyle"},[_vm._v("Completed : "+_vm._s(_vm.delayWhatsappBroadcastData.completed_count))]),_vm._v("  "),_c('span',{staticClass:"broadcastBarStyle"},[_vm._v("Failure : "+_vm._s(_vm.delayWhatsappBroadcastData.failure_count))]),_vm._v("  \n      "),(_vm.delayWhatsappBroadcastData.approval_status === 'approved')?_c('span',[(_vm.delayWhatsappBroadcastData.status === 'running')?_c('span',{staticClass:"broadcastControlButtons",attrs:{"data-id":_vm.delayWhatsappBroadcastData.id,"data-type":"pause"},on:{"click":function($event){return _vm.changeBroadcastStatus(
              ("pauseBroadcast/" + (_vm.delayWhatsappBroadcastData.id))
            )}}},[_c('img',{attrs:{"src":"https://img.icons8.com/ios-glyphs/20/000000/pause.png"}})]):_vm._e(),_vm._v("\n          \n        "),(_vm.delayWhatsappBroadcastData.status === 'running')?_c('span',{staticClass:"broadcastControlButtons",attrs:{"data-id":_vm.delayWhatsappBroadcastData.id,"data-type":"cancel"},on:{"click":function($event){return _vm.changeBroadcastStatus(
              ("cancelBroadcast/" + (_vm.delayWhatsappBroadcastData.id))
            )}}},[_c('img',{attrs:{"src":"https://img.icons8.com/ios-glyphs/20/000000/stop.png"}})]):_vm._e(),_vm._v("  \n        "),(_vm.delayWhatsappBroadcastData.status === 'paused')?_c('span',{staticClass:"broadcastControlButtons",attrs:{"data-id":_vm.delayWhatsappBroadcastData.id,"data-type":"resume"},on:{"click":function($event){return _vm.changeBroadcastStatus(
              ("resumeBroadcast/" + (_vm.delayWhatsappBroadcastData.id))
            )}}},[(_vm.getrole('cm1') || _vm.getrole('exed2'))?_c('img',{attrs:{"src":"https://img.icons8.com/ios-glyphs/20/000000/play.png"}}):_vm._e()]):_vm._e(),_vm._v("\n          \n        "),(_vm.delayWhatsappBroadcastData.status === 'cancelled')?_c('span',[_c('img',{attrs:{"src":"https://img.icons8.com/ios-glyphs/20/000000/cancel-2.png"}})]):_vm._e(),_vm._v("\n          \n        "),(_vm.delayWhatsappBroadcastData.status === 'completed')?_c('span',[_c('img',{attrs:{"src":"https://img.icons8.com/ios-glyphs/20/000000/ok.png"}})]):_vm._e()]):_vm._e(),_vm._v("\n        \n    ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }